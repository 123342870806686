import { REACT_APP_API_TOKEN, REACT_APP_BASE_URL } from ".";
import { useEffect, useState } from "react";

// создаём пользовательский хук? для реальзации и унификации всех запросов к api
  function  useDataFetch ({url}) {
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
         fetch(
            `${REACT_APP_BASE_URL}${url}`,
            {
                "Content-Type": "application/json",
    
                headers: {
                    "X-Auth-Token": REACT_APP_API_TOKEN,
                },
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setLoaded(true);
                    setItems(result);
                },
    
                (error) => {
                    setLoaded(true);
                    setError(error);
                }
            );
    }, [])
    
    return [loaded, error, items];
}

export default useDataFetch;